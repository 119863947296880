import React from "react"
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Navbar from "./Navbar";

const sections = [
  { title: 'Home', url: '/' },
  { title: 'Dev', url: '/dev' },
  { title: 'Gigs', url: '/gigs' },
  { title: 'Drawings', url: '/drawings' },
  { title: 'Board Games', url: '/boardgames'},
];

const Layout = ({children}) => (
  <React.Fragment>
    <Container>
      <Navbar sections={sections} />
      <Typography component="div">
        {children}
      </Typography>
    </Container>
  </React.Fragment>
);

export default Layout;
