import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  toolbar: {
    borderBottom: `1px solid black`,
    justifyContent: 'space-between',
    overflowX: 'auto',
    marginBottom: `40px`
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

const Navbar = props => {
  const classes = useStyles();
  const { sections } = props;

  return (
    <React.Fragment>
      <Toolbar component="nav" className={classes.toolbar}>
        {sections.map(section => (
          <Link
            color="inherit"
            noWrap
            key={section.title}
            variant="body2"
            href={section.url}
            className={classes.toolbarLink}
          >
            <h3>{section.title}</h3>
          </Link>
        ))}
      </Toolbar>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  sections: PropTypes.array,
};

export default Navbar;