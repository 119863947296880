import React from 'react';
import PropTypes from 'prop-types';
import favicon from '../images/favicon.ico'
import Helmet from 'react-helmet'

const SiteHelmet = ({ title="Home" }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>kks110 | {title}</title>
    <link rel="icon" href={favicon} />
  </Helmet>
);

SiteHelmet.propTypes = {
  title: PropTypes.string
};

export default SiteHelmet;